.slideshow, .footer-content {
  position:relative;
  margin:0 0 30px 0;
  .background {
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
  }
  .overlay {
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.55);

    .contact-block, .content-block {
      display:block;
    }
    .page-header {
      h1 {
        color: #fff;
        margin: 2em 0 0 0;
        padding: 0;
        border: none;
        text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      }
      border-color:#aaa;
    }
    h4 {
      color: #fff;
      margin: 2em 0 2em 0;
      padding: 0;
      border: none;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
    }
    p {
      color: #fff;
      margin:0;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      a {
        color: #fff;
      }
    }
    hr {
      border-color:#aaa;
    }

    .carousel-control {
      width:7%;
    }
    .item {
      width:100%;
      .carousel-caption {
        width:70%;
        padding-top:2.5em;
        position:relative;
        top:0;
        left:40px;
        text-align:left;

        @media (max-width: 991px) {
          width:90%;
          padding-top:2em;
        }

        @media (max-width: 767px) {
          padding-top:1em;
          left:20px;
        }


        p {
          text-align:left;
          margin:0 0 30px 0;
          font-size:1.6em;
          color:#ddd;
          line-height:1.6em;
          text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
          &:first-child {
            margin:0 0 20px 0;
          }


          @media (max-width: 767px) {
            margin:0 0 20px 0;
            font-size:1.0em;
            &:first-child {
              margin:0 0 10px 0;
            }
          }
        }
        h3 {
          text-align:left;
          margin:0 0 20px 0;
          font-size:3em;
          text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);

          @media (max-width: 767px) {
            font-size:2.0em;
            margin:0 0 20px 0;
          }
        }
        a {
          background:$brand-primary;
          padding:10px 20px;
          color:#fff;
          margin:10px 0 20px 0;
          &:hover {
            background:darken($brand-primary,10%);
            text-decoration:none;
          }

          @media (max-width: 767px) {

          }
        }



      }
    }


  }


}



.comparison-table {
  margin:0 0 20px 0;
  .row {
    width:100%;
    margin-bottom:4px;
    margin-left:0px;
    margin-right:0px;
  }
  .column {
    display:table;
    height:100%;
    vertical-align:middle;
    padding-top:0;
    padding-bottom:0;
    padding-left:2px;
    padding-right:2px;
    @media (max-width: 991px) {
      font-size:0.9em;
    }
    @media (max-width: 767px) {
      font-size:0.8em;
    }
    .main-title {
      display:table-cell;
      width:100%;
      vertical-align:middle;
      background:$brand-primary;
      color:#fff;
      font-weight:bold;
      padding:2px 5px;
      margin:0 0 2px 0;
      text-align:center;
    }
    .title {
      background:#ddd;
      color:#000;
      padding:2px 5px;
      display:table-cell;
      width:100%;
      vertical-align:middle;
      margin:0 0 2px 0;
    }
    .value {
      background:#efefef;
      color:#000;
      padding:2px 5px;
      display:table-cell;
      width:100%;
      vertical-align:middle;
      text-align:center;
      margin:0 0 2px 0;
    }
  }
}


.image-link {
  position:relative;
  margin-bottom:40px;
  a {
    img {
      width: 100%;
    }
    .overlay {
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: block;
      width: 100%;
      padding: 0.5em 30px 0.5em 0.5em;
      background: rgba(0, 0, 0, 0.5);
      transition: background-color 0.5s ease;
      font-size:1.2em;
      color:#fff;
      text-transform:uppercase;
      font-weight:bold;
      text-align:center;
      &:after {
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        font-family: FontAwesome;
        font-feature-settings: normal;
        font-kerning: auto;
        font-language-override: normal;
        font-size: inherit;
        font-size-adjust: none;
        font-stretch: normal;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        text-rendering: auto;
        content: "\f054";
        position:absolute;
        right:0.5em;
        top:0.7em;
      }
    }
    &:hover {
      .overlay {
        background:$brand-primary;
      }
    }

  }

}