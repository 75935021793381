
// downloads

.download {
  text-align:center;

  @media (max-width: 767px) {
    text-align:left;
  }

  a {
    font-size:1.4em;
    margin:0 0 20px 0;
    color:#000;
    text-decoration:none;
    display:inline-block;
    i {
      font-size:1.5em;
    }
    &:hover {
      color:$brand-primary;
      text-decoration:none;
    }
  }
}

.downloads-title {
  background:#000;
  color:#fff;
  margin-left:0px;
  margin-right:0px;
  h4 {
    margin:0;
    padding:0;
  }
  //margin:0;
  padding-top:10px;
  .wpb_text_column {
    margin-bottom:10px;
  }


}
.downloads-content {
  background:#efefef;
  padding:20px 0 20px 0;
  margin-left:0px;
  margin-right:0px;
}