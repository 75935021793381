.footer {
  margin:2em 0 2em 0;
  .gform_wrapper .top_label .gfield_label {
    display:none;
  }
  .legals {
    p {
      font-size:0.8em;
    }
  }
}