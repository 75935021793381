
.navbar {
  margin: 0 0 10px 0;
  background: $brand-primary;
  border:none;
  border-radius:0px;


  .navbar-nav {
    li {
      a {
        color: #fff;
        text-decoration: none;
        border: none;
        &:hover, &:focus, &.active {
          background: darken($brand-primary, 10%);
          color: #fff;
          text-decoration: none;
          border: none;
        }
      }
      &.active > a, &.active > a:hover {
        background: darken($brand-primary, 10%);
        color: #fff;
        text-decoration: none;
        border: none;
      }
      &.current-menu-ancestor > a {
        background: darken($brand-primary, 10%);
        color: #fff;
        text-decoration: none;
        border: none;
      }
    }
    > .open > a,
    > .open > a:focus,
    > .open > a:hover {
      background:darken($brand-primary,10%);
      color:#fff;
      text-decoration:none;
      border:none;
    }


    li:first-child {
      width: 0.4%;
    }
    li:last-child {
      width: 0.4%;
    }

    li {
      ul {
        li {
          width:100% !important;
          a {
            color:#000;
            &:hover {
              background: darken($brand-primary, 10%);
              color: #fff;
            }
          }
          &.active {
            a {
              background: darken($brand-primary, 10%);
              color:#fff;
            }
          }
        }
      }
    }



    @media (max-width: 1199px) {
      li {
        font-size: 0.75em;
      }
    }
    @media (max-width: 991px) {
      li {
        font-size: 1em;
      }
      li:first-child {
        width: 100%;
      }
      li:last-child {
        width: 100%;
      }
    }
  }

  @media (min-width: 992px) {
    .navbar-nav > li {
      float: none;
    }
  }

  @media (max-width: 991px) {
    margin-bottom:5px;
    background:none;
    min-height:0px;
    .navbar-collapse {
      border:none;
    }
    .navbar-toggle {
      margin-right:0px;
      margin-top:14px;
      @media (max-width: 991px) {
        margin-top:10px;
        margin-bottom:0px;
      }
      @media (max-width: 767px) {
        margin-top:4px;
        margin-bottom:0px;
      }
    }
    .navbar-nav {
      max-height:calc(100vh - 200px);
      overflow-y:auto;
      margin:10px 0px 20px 0px;
      border:1px solid #efefef;
      li {
        display:block;
        width:100%;
        a {
          color:#000;
          margin:0px;
          &:hover, &:focus {
            color:#fff;
            background:darken($brand-primary,10%);
          }
        }
        ul {
          margin:0px;
          padding:0px;
          li {
            background:#efefef;
            a {
              text-align:center;
              &:hover {
                background:#aaa !important;
                color:#fff !important;
              }
            }
          }
        }
        &.active > a, &.active > a:hover {
          background: darken($brand-primary, 10%);
          color: #fff;
          text-decoration: none;
          border: none;
        }
        &.current-menu-ancestor > a, &.current-menu-ancestor > a:hover {
          background: darken($brand-primary, 10%);
          color: #fff;
          text-decoration: none;
          border: none;
        }



      }
    }
  }
  @media (max-width: 767px) {
    margin-bottom:0px;
  }

}