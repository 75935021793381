.product {
  position:relative;
  width: 100%;
  display:block;
  background:white;
  border:1px solid #ccc;
  margin-bottom:2em;
  label {
    border:none;
    border-radius:0px;
    padding:0;
    text-align:left;
    width:100%;
    cursor:pointer;
    .product-title {
      h4 {
        text-align:center;
        margin: 0;
        padding: 0.5em 1em 0em 1em;
        font-weight:bold;
      }
      p {
        text-align:center;
        margin: 0;
        padding: 0 1em 0 1em;
        font-weight:normal;
      }
    }
    img {
      margin:0px auto;
    }
    span {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 0.5em;
      display: block;
      transition: background-color 0.5s ease;
      background: rgba(0, 0, 0, 0.5);
      font-size:1.2em;
      color:#fff;
      text-transform:uppercase;
      font-weight:bold;
      text-align:center;
      @media (max-width: 1199px) {
        font-size:1.0em;
      }
      @media (max-width: 991px) {
        font-size:1.2em;
      }
      &:after {
        -moz-osx-font-smoothing: grayscale;
        display: inline-block;
        font-family: FontAwesome;
        font-feature-settings: normal;
        font-kerning: auto;
        font-language-override: normal;
        font-size: inherit;
        font-size-adjust: none;
        font-stretch: normal;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        line-height: 1;
        text-rendering: auto;
        content: "\f054";
        position:absolute;
        right:0.5em;
        top:0.7em;
      }
    }
    &:hover {
      span {
        background:$brand-primary;
      }
    }
  }
}