.header {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  display:block;
  background:#ffffff;
  z-index:999;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.25);
  .brand {
    margin:0.7em 0;
    display:inline-block;
    @media (max-width: 767px) {
      margin:0.3em 0;
      img {
        max-height: 41px;
      }
    }
  }
  p {
    text-align:right;
    font-size:1.2em;
    margin:0.7em 0;
    i {
      display:none;
    }
    @media (max-width: 991px) {
      font-size:1em;
      margin:0;
    }
    @media (max-width: 767px) {
      font-size:0.8em;
      i {
        display:inline-block;
        margin-right:5px;
      }
    }

    a {
      color:#000;
      &:hover {
        color:darken($brand-primary,10%);
        text-decoration:none;
        border:none;
      }
    }
  }
  .header-telephone {
    @media (max-width:991px) {
      p {
        text-align:left;
      }
    }
  }
  .header-telephone, .header-email {
    @media (max-width:991px) {
      background: $brand-primary;

      p {
        padding-top: 4px;
        padding-bottom: 4px;
        color: #fff;
        a {
          color: #fff;
          &:hover {
            color: #efefef;
          }
        }
      }
    }
  }
}



