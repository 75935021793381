// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-default;
}


.gform_wrapper ul {
  @extend .list-unstyled;
}
.gform_wrapper li {
  @extend .form-group;
}
.gform_wrapper form {
  margin-bottom: 0;
}
.gform_wrapper .gfield_required {
  padding-left: 1px;
  color: $state-danger-text;
}
.ginput_container input,
.ginput_container select,
.ginput_container textarea {
  @extend .form-control;
}
.ginput_container textarea {
  height: auto;
}
.gform_button {
  @extend .btn;
  @extend .btn-primary;
}
.gform_wrapper .gfield_error {
  .gfield_label {
    color: $state-danger-text;
  }
  input,
  select,
  textarea {
    border-color: $alert-danger-border;
    background-color: $alert-danger-bg;
    color: $alert-danger-text;
    @include form-control-focus($alert-danger-text);
  }
}
.validation_error {
  @extend .alert;
  @extend .alert-danger;
}
#gforms_confirmation_message {
  @extend .alert;
  @extend .alert-success;
}

.gform_wrapper li.gfield.gfield_error {
  background:none !important;
  border:none !important;
}

.footer {
  .gform_wrapper li {
    margin:0 0 0 0;
  }
  .gfield_description.validation_message {
    color:#fff;
  }
  .gform_wrapper li.gfield.gfield_error {
    margin:0;
    padding:0;
  }
  .gform_confirmation_message {
    color:#fff;
    margin:2em 0 2em 0;
  }
}

.gchoice_5_42_1 {
  input {
    height:auto;
  }
}
.gform_wrapper .gfield_error input.datepicker_with_icon.small,
.gform_wrapper .gfield_error input.datepicker_with_icon.medium,
.gform_wrapper .gfield_error input.datepicker_with_icon.large {
  width:auto !important;
}
.ui-datepicker-year, .ui-datepicker-month {
  color:#000;
}





.considered {
  h3 {
    small {
      margin-left:10px;
      a {
        cursor:pointer;
      }
    }
  }

  .additional {
    label {
      display: block;
      margin-bottom: 15px;
      span {
        display: block;
        font-weight: normal;
        border: 2px solid #ccc;
        padding: 5px 10px;
        text-align: center;
        cursor: pointer;
        width: 100%;
        margin-top: -4px;
        h4 {
          color:#999;
        }
        p {
          color:#999;
        }
        .add-price {
          font-size:1.2em;
        }
        &:hover {
          border: 2px solid #000;
        }
      }
      :checked + span {
        border: 2px solid #419f41;
        h4 {
          color:#000;
        }
        p {
          color:#000;
        }
      }
      input {
        visibility: hidden;
        height: 0;
        display: inherit;
      }

    }

  }

}

.considered-modal {
  .download {
    a {
      font-size:1em;
    }
  }


}