


body {
  padding-top:133px;
  @media (max-width: 991px) {
    padding-top:110px;
  }
  @media (max-width: 767px) {
    padding-top:86px;
  }
}
body.mce-content-body {
  padding-top:0px !important;
}

body.admin-bar {
  padding-top:133px;
  .header {
    top:32px;
  }
  @media (max-width: 991px) {
    padding-top:110px;
    .header {
      top:32px;
    }
  }
  @media (max-width: 767px) {
    padding-top:86px;
    .header {
      top:44px;
    }
  }
}


.vc_col-xs-4 {
  @media (max-width: 767px) {
    width:50% !important;
  }
  @media (max-width: 400px) {
    width:100% !important;
  }
}